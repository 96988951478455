import { Row, Col } from 'react-bootstrap';
const Footer = () => {
    return (
        <Row>
            {
                window.innerWidth > 600 &&
                <>
                    <Col md={2} lg={2} xs={12} className="footer">
                        Ver 1.0.1
                    </Col>
                    <Col md={10} lg={10} xs={12} >
                        Copyrights &copy; 2022 All rights reserved
                    </Col>
                </>
            }
            {
                window.innerWidth <= 600 &&
                <>
                    <Col md={2} lg={2} xs={9} >
                        &copy; 2022 All rights reserved

                    </Col>
                    <Col md={2} lg={2} xs={3} >

                        Ver 1.0.1
                    </Col>
                </>
            }

        </Row>

    );
}

export default Footer;