import Sidebar from "../../components/Sidebar";
import { Container, Row, Col, Card, Button, Image, Figure } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import setting from '../../setting.json';
import Topbar from "../../components/Topbar";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from "react";

const schema = yup
    .object().shape({
        portal_name: yup.string().required('Portal Name the required'),
        facebook_id: yup.string(),
        facebook_user: yup.string(),
        instagram_user: yup.string(),
        youtube_user: yup.string(),
        pinterest_user: yup.string(),
        google_code: yup.string(),
        phone: yup.string().required('Phone the required'),


    })

const PortalInfo = () => {
    const [logo, setLogo] = useState('');
    const [icon, setIcon] = useState('');
    const [newsImage, setNewsImage] = useState('');
    const [categoryImage, setCategoryImage] = useState('');
    const [userImage, setUserImage] = useState('');

    const {
        register,
        reset,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(schema),
    });

    const Update = async (data) => {
        let ad = new FormData();
        ad.append('name', data.portal_name);
        ad.append('fb_id', data.facebook_id);
        ad.append('fb_user', data.facebook_user);
        ad.append('insta_user', data.instagram_user);
        ad.append('youtube_user', data.youtube_user);
        ad.append('pintrest_user', data.pinterest_user);
        ad.append('google_verification', data.google_code);
        ad.append('phone', data.phone);


        await fetch(setting.api + '/api/updatePortalInfo',
            {
                body: ad,
                method: "post",
                mode: "cors",
                headers: {
                    'Accept': 'application/FormData',
                }
            })
            .then(response => response)
            .then(data => {

            });
    }

    const getPortalInfo = async () => {
        var url = setting.api;
        await fetch(url + '/api/getPortalInfo',
            {
                method: "get",
                mode: "cors",
            })
            .then(response => response.json())
            .then(dd => {
                //console.log(dd);
                reset({
                    portal_name: dd.name,
                    facebook_id: dd.fb_id,
                    facebook_user: dd.fb_user,
                    instagram_user: dd.insta_user,
                    youtube_user: dd.youtube_user,
                    pinterest_user: dd.pintrest_user,
                    google_code: dd.google_verification,
                    phone: dd.phone,
                })
                setLogo(`${setting.url}/wp-content/uploads/default/${dd.logo}`);
                setIcon(`${setting.url}/wp-content/uploads/default/${dd.favicon}`);

            });
    }

    const getNewsDefaultImageUrl = async () => {
        await fetch(setting.api + '/api/getSetting?key=news',
            {
                method: "get",
                mode: "cors",
            })
            .then(response => response.json())
            .then(dd => {
                setNewsImage(`${setting.url}/wp-content/uploads/default/${dd.value}`);
            });
    }
    const getCategoryDefaultImageUrl = async () => {
        await fetch(setting.api + '/api/getSetting?key=category',
            {
                method: "get",
                mode: "cors",
            })
            .then(response => response.json())
            .then(dd => {
                setCategoryImage(`${setting.url}/wp-content/uploads/default/${dd.value}`);
            });
    }
    const getUserDefaultImageUrl = async () => {
        await fetch(setting.api + '/api/getSetting?key=user',
            {
                method: "get",
                mode: "cors",
            })
            .then(response => response.json())
            .then(dd => {
                setUserImage(`${setting.url}/wp-content/uploads/default/${dd.value}`);
            });
    }

    useEffect(() => {
        getPortalInfo();
        getNewsDefaultImageUrl();
        getCategoryDefaultImageUrl();
        getUserDefaultImageUrl();
    }, []);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }
    const uploadAssets = async (e, type) => {
        const file = e.target.files[0];

        switch (type) {
            case "logo":
                setLogo(URL.createObjectURL(e.target.files[0]));
                break;
            case "favicon":
                setIcon(URL.createObjectURL(e.target.files[0]));
                break;
            case "news":
                setNewsImage(URL.createObjectURL(e.target.files[0]));
                break;
            case "category":
                setCategoryImage(URL.createObjectURL(e.target.files[0]));
                break;
            case "user":
                setUserImage(URL.createObjectURL(e.target.files[0]));
                break;
        }
        const base64 = await convertBase64(file);
        console.log(base64);
        var fd = {
            "image": base64,
            "type": type
        }

        var url = setting.api;
        fetch(url + '/api/uploadAssets', {
            body: JSON.stringify(fd),
            method: 'POST',

        })
            .then(response => response)
            .then(json => {


            }
            );
    }



    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                {JSON.parse(localStorage.getItem('user')).role === 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Settings</h2>
                        <Row>
                            <Col md={7}>
                                <Card>
                                    <Card.Header className="head">
                                        Update setting
                                    </Card.Header>
                                    <Card.Body>
                                        <Form onSubmit={handleSubmit(Update)}>


                                            <Row>
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Portal Name</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Portal Name" {...register("portal_name")} />
                                                    {errors.portal_name && <p>{errors.portal_name.message}</p>}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Facebook ID</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Facebook ID" {...register("facebook_id")} />

                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Facebook User</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Facebook User" {...register("facebook_user")} />

                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Instagram User</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Instagram User" {...register("instagram_user")} />

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Youtube User</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Youtube User" {...register("youtube_user")} />

                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Pinterest User</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Pinterest User" {...register("pinterest_user")} />

                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Google Verification Code</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Google Verification Code" {...register("google_code")} />

                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={3} lg={3}>
                                                    <Form.Label className="mt-1 mb-3">Phone</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Control placeholder="Phone" {...register("phone")} />
                                                    {errors.phone && <p>{errors.phone.message}</p>}
                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col >
                                                    <Button type='submit' variant='success'  >
                                                        Update
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={5}>
                                <Card>
                                    <Card.Header className="head">
                                        Website Assets Setting
                                    </Card.Header>
                                    <Card.Body>

                                        <Row>
                                            <Col md={8} >
                                                <Form.Label className="mt-1 mb-3">Logo</Form.Label>
                                                <Form.Control type="file" onChange={(e) => uploadAssets(e, "logo")} />
                                            </Col>
                                            <Col md={4}>
                                                <Figure>
                                                    <Figure.Image
                                                        alt="171x180"
                                                        //src={`${setting.url}/wp-content/uploads/${logo}`}
                                                        src={logo}
                                                    />
                                                </Figure>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <Form.Label className="mt-1 mb-3">Favicon</Form.Label>
                                                <Form.Control type="file" onChange={(e) => uploadAssets(e, "favicon")} />
                                            </Col>

                                            <Col>
                                                <Figure>
                                                    <Figure.Image

                                                        alt="171x180"
                                                        src={icon}
                                                    />
                                                </Figure>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <Form.Label className="mt-1 mb-3">News Default Image</Form.Label>
                                                <Form.Control type="file" onChange={(e) => uploadAssets(e, "news")} />
                                            </Col>

                                            <Col>
                                                <Figure>
                                                    <Figure.Image

                                                        alt="171x180"
                                                        src={newsImage}
                                                    />
                                                </Figure>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <Form.Label className="mt-1 mb-3">Category Default Image</Form.Label>
                                                <Form.Control type="file" onChange={(e) => uploadAssets(e, "category")} />
                                            </Col>

                                            <Col>
                                                <Figure>
                                                    <Figure.Image

                                                        alt="171x180"
                                                        src={categoryImage}
                                                    />
                                                </Figure>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <Form.Label className="mt-1 mb-3">User Default Image</Form.Label>
                                                <Form.Control type="file" onChange={(e) => uploadAssets(e, "user")} />
                                            </Col>

                                            <Col>
                                                <Figure>
                                                    <Figure.Image

                                                        alt="171x180"
                                                        src={userImage}
                                                    />
                                                </Figure>
                                            </Col>
                                        </Row>



                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>


                    </Col>
                }
            </Row >
        </Container >
    );
}
export default PortalInfo;