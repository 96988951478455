import Sidebar from "../../components/Sidebar";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import setting from '../../setting.json';
import Topbar from "../../components/Topbar";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from "react";

const schema = yup
    .object().shape({
        adstxt: yup.string(),


    })

const Adstxt = () => {

    const {
        register,
        reset,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(schema),
    });

    const Update = async (data) => {
        let ad = new FormData();
        ad.append('value', data.adstxt);

        var url = setting.api;
        await fetch(url + '/api/updateAdsTxtFile',
            {
                body: ad,
                method: "post",
                mode: "cors", // no-cors, *cors, same-origin
                headers: {
                    'Accept': 'application/FormData',
                }
            })
            .then(response => response)
            .then(data => {

            });
    }

    const getAdsTxt = async () => {
        await fetch(setting.api + '/api/getAdsTxtFile')
            .then(response => response.text())
            .then(data => {
                console.log(data);
                reset({
                    "adstxt": data
                })
            });
    }



    useEffect(() => {
        getAdsTxt();


    }, []);

    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                {JSON.parse(localStorage.getItem('user')).role === 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Ads.txt Update</h2>

                        <Row>
                            <Col>
                                <Form onSubmit={handleSubmit(Update)}>
                                    <Card>
                                        <Card.Header className="head">
                                            Update Ads.txt
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col md={12} lg={12}>
                                                    <Form.Control
                                                        as="textarea"
                                                        placeholder="Leave a comment here"
                                                        rows={25}
                                                        {...register("adstxt")}
                                                        className="mb-3"
                                                    />
                                                    {errors.adstxt && <p>{errors.adstxt.message}</p>}
                                                </Col>
                                            </Row>
                                            <Button type='submit' variant='success'  >
                                                Update
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>

                    </Col>
                }
            </Row >
        </Container >
    );
}
export default Adstxt;