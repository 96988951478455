import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCardIcon from '@mui/icons-material/AddCard';
import PublishIcon from '@mui/icons-material/Publish';
import DraftsIcon from '@mui/icons-material/Drafts';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import SidebarMenu from 'react-bootstrap-sidebar-menu';

const Sidebar = () => {

    return (<>

        {
            window.innerWidth > 600 &&
            <Col md={2} className="navbar">
                <nav className="nav">
                    {
                        JSON.parse(localStorage.getItem('user')).role === 'admin' &&
                        <SidebarMenu>
                            <SidebarMenu.Body>
                                <SidebarMenu.Nav className="topmenu">
                                    <Link to="/dashboard"><DashboardIcon /> Home</Link>
                                </SidebarMenu.Nav>
                                <SidebarMenu.Nav className="topmenu">
                                    <Link to="/advertisement"><DraftsIcon /> Advertisement</Link>
                                </SidebarMenu.Nav>
                                <SidebarMenu.Nav className="topmenu">
                                    <Link to="/category"><DraftsIcon /> Category</Link>
                                </SidebarMenu.Nav>
                                <SidebarMenu.Nav className="topmenu">
                                    <Link to="/users"><DraftsIcon /> Users</Link>
                                </SidebarMenu.Nav>
                                <SidebarMenu.Nav className="topmenu">
                                    <Link to="/settings"><DraftsIcon />Settings</Link>
                                </SidebarMenu.Nav>

                                <SidebarMenu.Nav className="topmenu">
                                    <Link to="/portalinfo"><DraftsIcon /> Portal Info</Link>
                                </SidebarMenu.Nav>
                                <SidebarMenu.Nav className="topmenu">
                                    <Link to="/adstxt"><DraftsIcon /> Ads.txt Info</Link>
                                </SidebarMenu.Nav>

                                <SidebarMenu.Nav className="topmenu">
                                    <Link to="/feeds"><DraftsIcon /> Feeds</Link>
                                </SidebarMenu.Nav>
                                <SidebarMenu.Nav className="topmenu">
                                    <Link to="/sitemap"><AccountTreeIcon /> Sitemap</Link>
                                </SidebarMenu.Nav>
                                <SidebarMenu.Sub className="topmenu">
                                    <SidebarMenu.Sub.Toggle className="algn">
                                        <Link to="#"><WebStoriesIcon /> Report <KeyboardArrowDownIcon /></Link>
                                    </SidebarMenu.Sub.Toggle>
                                    <SidebarMenu.Sub.Collapse >
                                        <SidebarMenu.Nav>
                                            <Link to="/userreport"><AddCardIcon /> User Report</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/report"><PublishIcon /> Monthly Report</Link>
                                        </SidebarMenu.Nav>


                                    </SidebarMenu.Sub.Collapse>
                                </SidebarMenu.Sub>

                                {/*  <SidebarMenu.Nav className="topmenu">
                                    <Link to="/deleteCache"><DraftsIcon /> Delete Cache</Link>
                                </SidebarMenu.Nav>
                                */}
                            </SidebarMenu.Body>
                        </SidebarMenu>
                    }
                    {
                        JSON.parse(localStorage.getItem('user')).role === 'editor' &&
                        <SidebarMenu>
                            <SidebarMenu.Body>
                                <SidebarMenu.Nav className="topmenu">
                                    <Link to="/dashboard"><DashboardIcon /> Dashboard</Link>
                                </SidebarMenu.Nav>
                                <SidebarMenu.Sub className="topmenu">
                                    <SidebarMenu.Sub.Toggle className="algn">
                                        <Link to="#"><AddCardIcon /> News <KeyboardArrowDownIcon /></Link>
                                    </SidebarMenu.Sub.Toggle>
                                    <SidebarMenu.Sub.Collapse >
                                        <SidebarMenu.Nav>
                                            <Link to="/addNews"><AddCardIcon /> Add News</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/publishedNews"><PublishIcon /> Published News</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/draftNews"><DraftsIcon /> Draft News</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/deletedNews"><DeleteIcon /> Deleted News</Link>
                                        </SidebarMenu.Nav>

                                    </SidebarMenu.Sub.Collapse>
                                </SidebarMenu.Sub>

                                <SidebarMenu.Sub className="topmenu">
                                    <SidebarMenu.Sub.Toggle className="algn">
                                        <Link to="#"><WebStoriesIcon /> Web Stories <KeyboardArrowDownIcon /></Link>
                                    </SidebarMenu.Sub.Toggle>
                                    <SidebarMenu.Sub.Collapse >
                                        <SidebarMenu.Nav>
                                            <Link to="/addStory"><AddCardIcon /> Add Story</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/webstory"><PublishIcon /> Published Story</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/webstorydraft"><DraftsIcon /> Draft Story</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/webstorydeleted"><DeleteIcon /> Deleted Story</Link>
                                        </SidebarMenu.Nav>

                                    </SidebarMenu.Sub.Collapse>
                                </SidebarMenu.Sub>
                                <SidebarMenu.Sub className="topmenu">
                                    <SidebarMenu.Sub.Toggle className="algn">
                                        <Link to="#"><WebStoriesIcon /> Report <KeyboardArrowDownIcon /></Link>
                                    </SidebarMenu.Sub.Toggle>
                                    <SidebarMenu.Sub.Collapse >
                                        <SidebarMenu.Nav>
                                            <Link to="/userreport"><AddCardIcon /> User Report</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/report"><PublishIcon /> Monthly Report</Link>
                                        </SidebarMenu.Nav>


                                    </SidebarMenu.Sub.Collapse>
                                </SidebarMenu.Sub>



                            </SidebarMenu.Body>
                        </SidebarMenu>
                    }
                    {
                        JSON.parse(localStorage.getItem('user')).role === 'correspondent' &&
                        <SidebarMenu>
                            <SidebarMenu.Body>
                                <SidebarMenu.Nav className="topmenu">
                                    <Link to="/dashboard"><DashboardIcon /> Dashboard</Link>
                                </SidebarMenu.Nav>
                                <SidebarMenu.Sub className="topmenu">
                                    <SidebarMenu.Sub.Toggle className="algn">
                                        <Link to="#"><AddCardIcon /> News <KeyboardArrowDownIcon /></Link>
                                    </SidebarMenu.Sub.Toggle>
                                    <SidebarMenu.Sub.Collapse >
                                        <SidebarMenu.Nav>
                                            <Link to="/addNews"><AddCardIcon /> Add News</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/publishedNews"><PublishIcon /> Published News</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/draftNews"><DraftsIcon /> Draft News</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/deletedNews"><DeleteIcon /> Deleted News</Link>
                                        </SidebarMenu.Nav>

                                    </SidebarMenu.Sub.Collapse>
                                </SidebarMenu.Sub>

                                <SidebarMenu.Sub className="topmenu">
                                    <SidebarMenu.Sub.Toggle className="algn">
                                        <Link to="#"><WebStoriesIcon /> Web Stories <KeyboardArrowDownIcon /></Link>
                                    </SidebarMenu.Sub.Toggle>
                                    <SidebarMenu.Sub.Collapse >
                                        <SidebarMenu.Nav>
                                            <Link to="/addStory"><AddCardIcon /> Add Story</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/webstory"><PublishIcon /> Published Story</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/webstorydraft"><DraftsIcon /> Draft Story</Link>
                                        </SidebarMenu.Nav>
                                        <SidebarMenu.Nav>
                                            <Link to="/comingSoon"><DeleteIcon /> Deleted Story</Link>
                                        </SidebarMenu.Nav>

                                    </SidebarMenu.Sub.Collapse>
                                </SidebarMenu.Sub>


                                <SidebarMenu.Nav className="topmenu">
                                    <Link to="/sitemap"><AccountTreeIcon /> Sitemap</Link>
                                </SidebarMenu.Nav>
                            </SidebarMenu.Body>
                        </SidebarMenu>
                    }


                </nav>
            </Col>
        }

    </>
    );
}

export default Sidebar;