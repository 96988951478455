import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCardIcon from '@mui/icons-material/AddCard';
import PublishIcon from '@mui/icons-material/Publish';
import DraftsIcon from '@mui/icons-material/Drafts';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import setting from '../setting.json';

const Topbar = () => {
    const [name, setName] = useState('');
    const toggleMenu = () => {
        //console.log('toggle');
        var clsnm = document.getElementById('mobilemenu').getAttribute('class');
        var res = clsnm.search('hidemenu');
        //console.log(res);
        if (res !== -1) {
            document.getElementById('mobilemenu').removeAttribute('class', 'hidemenu');
            document.getElementById('mobilemenu').setAttribute('class', 'showmenu');
        } else {
            document.getElementById('mobilemenu').removeAttribute('class', 'showmenu');
            document.getElementById('mobilemenu').setAttribute('class', 'hidemenu');
        }
    }
    useEffect(() => {
        getCompanyProfile();

    }, []);
    const getCompanyProfile = async () => {
        var url = setting.api;

        await fetch(url + '/api/getCompanyProfile')
            .then(response => response.json())
            .then(json => {
                setName(json.name);


            }
            );
    }
    const capitalizeFirst = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    return <>
        {
            window.innerWidth > 600 &&
            <Row className="topbar">

                <Col md={2} >

                    <Link to="/dashboard" className="logo"> {name}</Link>


                </Col>
                <Col md={6} className="flleft">
                    <Link to="/dashboard" className="links"><DashboardIcon /> Home</Link>
                    <Link to="/addNews" className="links"><AddCardIcon /> Add News</Link>
                    <Link to="/publishedNews" className="links"><PublishIcon /> Published News</Link>

                </Col>

                <Col md={4}>
                    <Link to="/profile" className="links"><AccountCircleIcon />  {JSON.parse(localStorage.getItem('user')).fname} ({
                        capitalizeFirst(JSON.parse(localStorage.getItem('user')).role)

                    })</Link>
                    <Link to="/logout" className="links"><LogoutIcon /> Logout</Link>
                </Col>
            </Row>
        }
        {
            window.innerWidth <= 600 &&
            <Row className="topbar">
                <Col xs={2} className="menuicon">
                    <MenuIcon onClick={toggleMenu} />
                </Col>
                <Col xs={10} className="mobiletitle">
                    {setting.name}
                </Col>

                <Col xs={12} id="mobilemenu" className="hidemenu">
                    <Link to="/dashboard" className="links"><DashboardIcon /> Home</Link><br />
                    <Link to="/addNews" className="links"><AddCardIcon /> Add News</Link><br />
                    <Link to="/publishedNews" className="links"><PublishIcon /> Published News</Link><br />
                    <Link to="/draftNews" className="links"><DraftsIcon /> Draft News</Link><br />
                    <Link to="/deletedNews" className="links"><DeleteIcon /> Deleted News</Link><br />
                    <Link to="/profile" className="links"><AccountCircleIcon />  {sessionStorage.getItem('name')}</Link><br />
                    <Link to="/logout" className="links"><LogoutIcon /> Logout</Link><br />
                </Col>
            </Row>
        }
    </>
}

export default Topbar;
