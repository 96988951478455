import Sidebar from "../components/Sidebar";
import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Topbar from "../components/Topbar";
import Footer from "../components/Footer";
import setting from '../setting.json';
const WebStoryDeleted = () => {

    // const [category, setCategory] = useState(null);
    const [list, setList] = useState('');

    useEffect(() => {
        getNews();
    }, []);


    const getNews = async () => {
        var url = setting.api;
        await fetch(
            url + '/api/getDeletedWebStory')
            .then(response => response.json())
            .then(json => {

                setList(json);

            }
            );
    }
    const restoreWebstory = async (id) => {
        var url = setting.api;
        //var id = e.target.value;
        await fetch(
            url + '/api/restoreWebstory?id=' + id)
            .then(response => response.json())
            .then(json => {
                getNews();
            }
            );
    }
    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>

                <Sidebar />
                <Col md={10} lg={10} className="rightsect">
                    <h2 className="pagetitle">Deleted Web Story </h2>
                    <Card>
                        <Card.Body>


                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Title</th>
                                        <th>Sub Title</th>
                                        <th>Author</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!list &&
                                        <tr>
                                            <td id="norecord">No Record found</td>
                                        </tr>
                                    }

                                    {list && list.map((r, i) => (
                                        <tr key={r.id}>
                                            <td>{i + 1}</td>
                                            <td><a href={`${setting.url}/webstory/${r.slug}`} target="_blank" rel="noreferrer">{r.title}</a></td>
                                            <td>{r.sub_title}</td>
                                            <td>{r.user}</td>
                                            <td>{r.pubDate}</td>
                                            <td>

                                                <Button className="delete" size="sm" variant="warning" data={r.id} onClick={() => restoreWebstory(r.id)}>Restore</Button>
                                            </td>
                                        </tr>
                                    ))}


                                </tbody>
                            </Table>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Footer />
        </Container >
    );
}
export default WebStoryDeleted;