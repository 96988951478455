import { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Table, Modal, Form, InputGroup } from 'react-bootstrap';
import Topbar from "../../components/Topbar";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import AddBoxIcon from '@mui/icons-material/AddBox';
import setting from '../../setting.json';
const Profile = () => {
    const [show, setShow] = useState(false);
    const [news, setNews] = useState(0);
    const [image, setImage] = useState(0);
    const [totalNews, setTotalNews] = useState(0);
    const [totalMedia, setTotalMedia] = useState(0);
    const handleClose = () => setShow(false);

    const getSitemapSetting = async () => {
        var url = setting.api;
        await fetch(url + '/api/getSitemapSetting?type=sitemap')
            .then(response => response.json())
            .then(json => {
                setImage(json.image_sitemap_size);
                setNews(json.news_sitemap_size);
            }
            );
    }
    const getTotalNewsCount = async () => {
        var url = setting.api;
        await fetch(url + '/api/getTotalNewsCount')
            .then(response => response.json())
            .then(json => {
                setTotalNews(json.data);
            }
            );
    }
    const getTotalMediaCount = async () => {
        var url = setting.api;
        await fetch(url + '/api/getTotalMediaCount')
            .then(response => response.json())
            .then(json => {
                setTotalMedia(json.data);
            }
            );
    }
    useEffect(() => {
        getTotalNewsCount();
        getTotalMediaCount();
        getSitemapSetting();
    }, []);
    const regenerateNewsSitemap = async () => {
        var url = setting.api;
        await fetch(url + '/api/regenerateNewsSitemap?size=' + news)
            .then(response => response)
            .then(json => {

            }
            );
    }
    const regenerateImageSitemap = async () => {
        var url = setting.api;
        await fetch(url + '/api/regenerateImageSitemap?size=' + image)
            .then(response => response)
            .then(json => {

            }
            );
    }
    const regenerateQuickNews = async () => {
        var url = setting.api;
        await fetch(url + '/api/regenerateQuickNews?size=30')
            .then(response => response)
            .then(json => {

            }
            );
    }


    return (



        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>

                <Sidebar />



                <Col md={10} lg={10} xs={12} className="rightsect">

                    <h3 className="pagetitle"><AddBoxIcon className="titleicon" /> Sitemap Setting</h3>

                    <Row>
                        <Col md={3} >

                            <Card >
                                <Card.Body>
                                    <Card.Title>Regenerate Sitemap </Card.Title>
                                    <p>Total News : {totalNews}</p>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="News Sitemap size"
                                            onChange={(e) => setNews(e.target.value)}
                                            value={news}
                                            aria-label="News Sitemap size"
                                            aria-describedby="basic-addon2"
                                        />

                                        <Button variant="primary" type="submit" id="preview" onClick={regenerateNewsSitemap}>
                                            Update
                                        </Button>
                                    </InputGroup>

                                </Card.Body>
                            </Card>
                            <Card >
                                <Card.Body>
                                    <Card.Title>Regenerate Image Sitemap</Card.Title>
                                    <p>Total Media : {totalMedia}</p>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Image Sitemap size"
                                            onChange={(e) => setImage(e.target.value)}
                                            value={image}
                                            aria-label="Image Sitemap size"
                                            aria-describedby="basic-addon2"
                                        />

                                        <Button variant="primary" type="submit" id="preview" onClick={regenerateImageSitemap}>
                                            Update
                                        </Button>
                                    </InputGroup>

                                </Card.Body>
                            </Card>
                            <Card >
                                <Card.Body>
                                    <Card.Title>Regenerate Quick News</Card.Title>
                                    <Button variant="primary" type="submit" id="preview" onClick={regenerateQuickNews}>Update </Button>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col md={9} xs={12}>
                            <Card >
                                <Card.Body>
                                    <p>Please take technical advice before performing these task otherwise it will impact your google search result.</p>
                                </Card.Body>
                            </Card>
                            <Card >
                                <Card.Body>
                                    <Card.Title>Sitemap List</Card.Title>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Sr.no.</th>
                                                <th>Url</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>News Sitemap</td>
                                                <td>{setting.url}/sitemap.xml</td>
                                                <td><a href={`${setting.url}/sitemap.xml`} target="_blank" rel="noreferrer">View</a></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Image Sitemap</td>
                                                <td>{setting.url}/imagesitemap.xml</td>
                                                <td><a href={`${setting.url}/imagesitemap.xml`} target="_blank" rel="noreferrer">View</a></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Static Page</td>
                                                <td>{setting.url}/staticpages.xml</td>
                                                <td><a href={`${setting.url}/staticpages.xml`} target="_blank" rel="noreferrer">View</a></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Web Story</td>
                                                <td>{setting.url}/storysitemap.xml</td>
                                                <td><a href={`${setting.url}/storysitemap.xml`} target="_blank" rel="noreferrer">View</a></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Category</td>
                                                <td>{setting.url}/category.xml</td>
                                                <td><a href={`${setting.url}/category.xml`} target="_blank" rel="noreferrer">View</a></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>




                        </Col>
                    </Row>

                </Col>
            </Row>


            <Footer />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Sitemap</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sitemap Updated</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </Container>

    );
}

export default Profile;