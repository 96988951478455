import { Button, Row, Col, Figure } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { ReactMediaLibrary } from 'react-media-library';
import React from "react";
import { useToast } from 'rc-toastr';
import setting from '../setting.json';
const MediaGallery = ({ img, groupid }) => {

    const [groupId, setGroupId] = useState(groupid);
    const [preview, setPreview] = useState('');
    const [display, setDisplay] = useState(false);
    const [fileLibraryList, setFileLibraryList] = useState([]);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const { toast } = useToast();
    //const [position, setPosition] = useState(0);
    async function uploadCallback(fileBase64, fileMeta) {

        //console.log(fileMeta.type);
        if (fileMeta.size <= 7000000) {
            try {
                var url = setting.api;
                const formData = new FormData();
                formData.append("image", fileBase64);
                formData.append("filename", fileMeta.fileName);
                await fetch(url + '/api/uploadImage', {
                    method: 'POST',
                    body: formData

                })
                    .then(response => response.json())
                    .then(json => {
                        //console.log(json);
                        //toast.success("Media uploaded Successfully.");
                        var path = setting.api + '/wp-content/uploads/' + json.location + '/' + json.image;
                        setPreview(path);
                        setGroupId(json.group_id);
                        setDisplay(false);
                        getMediaList();

                    }
                    );

            } catch (err) {
                //console.log(err);
            }
        } else {
            toast.error("File size too large .Please upload 7 MB Max.");
            alert('File size too large .Please upload 7 MB Max.');
        }



    }


    function selectCallback(item) {

        setDisplay(false);
        setPreview(item.thumbnailUrl)
        setGroupId(item.group_id)
    }




    async function deleteCallback(item) {
        // TODO Delete file from backend service
        /*  const result = await fileDelete(item);
 
         if (result.success) {
             // Deleted file 
             // TODO Reacquire file list from database
             const newFileList = await getNewFileList();
             setFileLibraryList(newFileList);
         } */
    }


    const getMediaList = async (event) => {

        try {
            var url = setting.api;
            await fetch(url + '/api/getMediaList', {
                method: 'GET',
            })
                .then(response => response.json())
                .then(json => {
                    //console.log(json);
                    setFileLibraryList(json);
                }
                );

        } catch (err) {
            console.log(err);
        }

    }
    const getDefaultImage = async (event) => {

        try {
            var url = setting.api;
            await fetch(url + '/api/getSetting?key=news', {
                method: 'GET',
            })
                .then(response => response.json())
                .then(json => {

                    setPreview(setting.api + '/wp-content/uploads/default/' + json.value);
                }
                );

        } catch (err) {
            console.log(err);
        }

    }
    useEffect(() => {

        document.getElementById("preview").setAttribute('src', preview);
    }, [groupId]);


    useEffect(() => {
        getDefaultImage();
        document.getElementById("preview").setAttribute('src', preview);
        getMediaList();
        img ? setPreview(img) : setPreview(preview);

    }, []);

    return (
        <Row>
            <Col md={6} >
                <Figure>
                    <Figure.Image
                        className="preview"
                        id="preview"
                        width={171}
                        height={180}
                        alt="171x180"
                        src={preview}
                    />
                </Figure>
                <input type="hidden" value={groupId} id="groupid" />
            </Col>
            <Col md={6} >
                <Button variant="primary" onClick={() => setDisplay(true)}>
                    Media Library
                </Button>


                <ReactMediaLibrary
                    show={display}
                    onHide={() => setDisplay(false)}
                    fileUploadCallback={uploadCallback}
                    fileLibraryList={fileLibraryList}
                    fileSelectCallback={selectCallback}

                />

            </Col>
        </Row>
    );
}
export default MediaGallery;