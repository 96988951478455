import { Container, Row, Col, Card, Form, Button, Image } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams, useHistory } from 'react-router-dom';
import Topbar from '../components/Topbar';
import Sidebar from "../components/Sidebar";
import MediaGallery from '../components/MediaGallery';
import setting from '../setting.json';
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from 'uuid';
import EditIcon from '@mui/icons-material/Edit';

const EditStory = () => {
    const { id } = useParams();
    const history = useHistory();
    const [num, setNum] = useState(1);
    const [list, setList] = useState([]);
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [key, setKey] = useState('');
    const [desc, setDesc] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    useEffect(() => {
        getStoryById();

    }, []);
    const getStoryById = async () => {
        var url = setting.api;
        await fetch(url + '/api/getStoryById?id=' + id)
            .then(response => response.json())
            .then(json => {
                console.log(json);

                setList(JSON.parse(json.slides));
                setKey(json.meta_key);
                setDesc(json.meta_desc);
                setSlug(json.slug);
                setNum(JSON.parse(json.slides).length);
            }
            );
    }
    const add = (e) => {
        e.preventDefault();

        var obj = {
            "id": uuidv4(),
            "title": title,
            "subtitle": subTitle,
            "groupid": document.getElementById('groupid').value,
            "position": num,
            "image": document.getElementById('preview').getAttribute("src"),

        }
        console.log(list);
        var list2 = list;
        list2.push(obj);
        setList(list2);
        document.getElementById("title").value = '';
        document.getElementById("subtitle").value = '';
        setNum(num + 1);
    }

    const deleteStory = (e) => {
        console.log(e);
        var stories = list.filter((r) => r.id !== e);
        setList(stories);
    }

    /*     function convert(str) {
            var date = new Date(str),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2),
                hours = ("0" + date.getHours()).slice(-2),
                minutes = ("0" + date.getMinutes()).slice(-2);
            var dt = date.getFullYear() + '-' + mnth + '-' + day + ' ' + hours + ':' + minutes + ':00';
            return dt;
        } */
    const updateStory = async (event) => {
        console.log('test');
        event.preventDefault();
        const formData = new FormData();
        formData.append("slides", JSON.stringify(list));
        formData.append("meta_key", key);
        formData.append("meta_desc", desc);
        formData.append("slug", slug);
        formData.append("id", id);
        var url = setting.api;
        fetch(url + '/api/updateWebStory', {
            method: 'POST',
            body: formData
        })
            .then(response => response)
            .then(json => {
                console.log(json);
                history.push('/webstory');

            }
            );
    }
    /* const deleteWebStory = async (event) => {
        console.log('test');
        event.preventDefault();

        var url = setting.api;
        fetch(url + '/api/deleteWebStory?id=' + id)
            .then(response => response)
            .then(json => {
                console.log(json);
                history.push('/webstory');

            }
            );
    } */

    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                <Col md={10} lg={10} xs={12} className="rightsect">
                    <h3 className="pagetitle">
                        <EditIcon className="titleicon" /> Edit Web story {'  '}
                        <a href={`${setting.api}/webstory/${slug}`} id="viewpost" target="_blank" rel="noreferrer">View Post</a>
                    </h3>

                    <Form >
                        <Row>
                            <Col md={9} className="mb-12" id="parent">
                                <Card className="mt-20">
                                    <Card.Body >
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Slug</Form.Label>
                                                    <Form.Control type="text" placeholder="Slug" id="slug" value={slug} onChange={(e) => setSlug(e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                <Card className="mt-20">
                                    <Card.Body >
                                        <Row>

                                            <Col md={9} className="mb-20" >
                                                <Form.Group className="mb-3" >
                                                    <Form.Control type="text" placeholder="Enter title" id="title" onChange={(e) => setTitle(e.target.value)} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" >

                                                    <Form.Control type="text" placeholder="Enter sub title" id="subtitle" onChange={(e) => setSubTitle(e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={3} className="mb-20">
                                                <Row>
                                                    <Col md={12}>

                                                        <Form.Group className="mb-3">

                                                            <MediaGallery />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">

                                                            <Button variant="warning" size="sm" onClick={add}>Add</Button>
                                                        </Form.Group>

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {list && list.map((r) =>
                                    <Card className="mt-20" key={r.id}>
                                        <Card.Body >
                                            <Row>
                                                <Col md={4}>
                                                    <Image
                                                        className="preview"
                                                        id="preview"
                                                        width={100}
                                                        height={110}
                                                        alt="171x180"
                                                        src={r.image}
                                                    />
                                                </Col>
                                                <Col md={7}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <h5>{r.title}</h5>
                                                        </Col>
                                                        <Col md={12}>
                                                            <p>{r.subtitle}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={1}>
                                                    <Button onClick={() => deleteStory(r.id)}>X</Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                )}
                            </Col>
                            <Col md={3} xs={12} >
                                <Card className="mt-20">
                                    <Card.Header className="cardhead">Meta Info</Card.Header>
                                    <Card.Body >
                                        <Form.Group className="mb-3" >
                                            <Form.Control type="text" placeholder="Meta Tag" id="title" defaultValue={key}
                                                onChange={(e) => setKey(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" >

                                            <Form.Control type="text" placeholder="Meta Description" id="subtitle" defaultValue={desc}
                                                onChange={(e) => setDesc(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Card.Body>
                                </Card>

                                {/* <Card >
                                    <Card.Header className="cardhead">Category</Card.Header>
                                    <Card.Body>
                                        <div className="mb-3 catsect">
                                            <CategoryTree
                                                list={categories}
                                                selectCategory={selectCategory}
                                                newscategory={newscategory}
                                            />
                                        </div>
                                    </Card.Body>
                                </Card> */}

                                <Card className="mt-20">
                                    <Card.Header className="cardhead">Publish</Card.Header>
                                    <Card.Body >
                                        <Form.Group className="mb-3" id="meta4" controlId="schedule" >
                                            <Form.Label>Schedule At</Form.Label>
                                            <DatePicker
                                                className="form-control"
                                                selected={startDate}
                                                showTimeSelect
                                                dateFormat="Pp"
                                                timeIntervals="5"
                                                onChange={(date) => setStartDate(date)} />
                                        </Form.Group>
                                    </Card.Body>
                                    <Card.Footer className="cardhead">
                                        <div className="d-grid gap-2">
                                            <Button variant="primary" type="button" onClick={updateStory}>Update </Button>
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container >
    );
}
export default EditStory;